// Classes
import BaseService from './base-service';

// Utils
import { get } from 'lodash';

/**
 * The name of the key where we store the last accessed SSO domain
 * @const {string} LAST_SSO_DOMAIN_STORE_KEY
 */
const LAST_SSO_DOMAIN_STORE_KEY = 'last_sso_domain';

/**
 * Provides methods necessary for Backstage SSO authentication.
 *
 * @example
 * import SingleSignOnService from 'libs/services/sso';
 * ...
 * const SingleSignOnService = new SingleSignOnService();
 */
export default class SingleSignOnService extends BaseService {

    /**
     * @param {Object} services services on which SingleSignOnService depends
     * @param {RouterService} services.router router service
     */
    constructor({ router, store }) {
        super();
        this.router = router;
        this.store = store;
    }

    /**
     * Checks if a given SSO domain exists.
     *
     * @param {String} ssoDomain name of the SSO domain
     *
     * @return {Promise<boolean>} true if the sso domain exists, else false.
     */
    async domainExists(ssoDomain) {
        const ssoUrl = this.router.getSSOUrl(ssoDomain);
        try {
            const response = await this.head(ssoUrl);
            if (response.status === 200) {
                return true;
            }
            throw new Error(`Unexpected response status ${response.status} from HEAD ${ssoUrl}`);
        } catch (err) {
            if (get(err, 'response.status') === 404) {
                return false;
            }
            throw err;
        }
    }

    /**
     * Saves the requested domain into the local storage for future processing
     *
     * @param {string} ssoDomain name of the SSO domain
     */
    storeDomain(ssoDomain) {
        this.store.set(LAST_SSO_DOMAIN_STORE_KEY, ssoDomain);
    }

    /**
     * Gets the last accessed SSO domain if it exists
     *
     * @returns {string|undefined} the last accessed SSO domain if it exists
     */
    getLastDomain() {
        return this.store.get(LAST_SSO_DOMAIN_STORE_KEY);
    }

    /**
     * Discards the last accessed domain
     */
    forgetLastDomain() {
        this.store.remove(LAST_SSO_DOMAIN_STORE_KEY);
    }
}
