// Utils
import { get, isEmpty } from 'lodash';

// Constants
import { MIN_PASSWORD_LENGTH } from 'libs/utils/constants';

const ERROR_MESSAGES = {
    'Short Password': 'login.errors.short_password',
    'Invalid Password': 'login.errors.invalid_password',
    'Invalid Email': 'login.errors.invalid_email',
    'Missing Acceptance Clauses': 'login.errors.missing_acceptance_clauses',
};

const ERROR_MESSAGES_REPLACEMENTS = {
    'login.errors.short_password': [MIN_PASSWORD_LENGTH]
};

export default {
    data() {
        return {
            errorMessage: null,
            errorMessageReplacements: [],
            showNewTokenLink: false
        };
    },

    methods: {
        /**
         *
         * @param {Error} error the occurred error
         */
        handleError(error) {
            const { status } = error.response || { status: 400 };
            const data = get(error, 'response.data', error.data || {});

            console.error(`[${this.$options.name}] An error occurred:`, isEmpty(data) ? error : data);

            const reason = data.reason || data.error;

            if (reason?.includes('expired token')) {
                this.$notify({
                    group: 'flashes',
                    text: this.$i18n.t('login.errors.activation_token_expired').toString(),
                    type: 'warning',
                    duration: 15000
                });

                this.errorMessage = 'login.errors.expired_token';
                this.showNewTokenLink = true;
                this.$router.push(this.$const.ROUTE_LOGIN);

            } else if (status === 404 || (reason?.includes('invalid token'))) {
                this.errorMessage = 'login.errors.invalid_link';
                this.showNewTokenLink = true;

            } else if (status === 401) {
                this.$router.push(this.$const.ROUTE_LOGIN);

            } else if (status === 409) {
                this.errorMessage = 'login.errors.password_reuse';

            } else if (data.error) {
                this.errorMessage = ERROR_MESSAGES[data.error] || 'login.errors.generic';

            } else {
                this.errorMessage = 'login.errors.generic';
            }

            this.errorMessageReplacements = ERROR_MESSAGES_REPLACEMENTS[this.errorMessage];
        },

        /**
         * Resets all the errors and component's default
         */
        reset() {
            this.errorMessage = null;
            this.errorMessageReplacements = [];
            this.showNewTokenLink = false;
        }
    }
};
