/** @module Docs:Router */

// Plugins
import VueRouter from 'vue-router';

// Components
import ConfirmAccount from 'auth/components/ConfirmAccount.vue';
import GenerateActivationLink from 'auth/components/GenerateActivationLink.vue';
import Login from 'auth/components/Login.vue';
import LoginWithSSO from 'auth/components/LoginWithSSO.vue';
import MFAPhoneQuery from 'auth/components/MFAPhoneQuery.vue';
import MFAValidation from 'auth/components/MFAValidation.vue';
import NewPassword from 'auth/components/NewPassword.vue';
import NewPasswordMFA from 'auth/components/NewPasswordMFA.vue';
import ResetRequest from 'auth/components/ResetRequest.vue';
import SsoRedirect from 'auth/components/SsoRedirect.vue';

// Constants
import {
    ROUTE_CONFIRM_ACCOUNT,
    ROUTE_LOGIN,
    ROUTE_LOGIN_WITH_MFA,
    ROUTE_LOGIN_WITH_SSO,
    ROUTE_MFA_PHONE,
    ROUTE_RESET_PASSWORD,
    ROUTE_RESET_PASSWORD_MFA,
    ROUTE_SEND_RESET_TOKEN,
    ROUTE_SSO_DIRECT_ACCESS,
    ROUTE_VALIDATE_ACCOUNT
} from 'libs/utils/constants';

/**
 * Route name of the page for sending the reset token
 * @const {String} SEND_RESET_TOKEN_ROUTE
 */
export const SEND_RESET_TOKEN_ROUTE = 'sendPasswordToken';

/**
 * Route name of the page for sending account validation email
 * @const {String} SEND_VALIDATE_ACCOUNT_REQUEST_ROUTE
 */
export const SEND_VALIDATE_ACCOUNT_REQUEST_ROUTE = 'validateAccount';

/**
 * Route name of the reset password page.
 * @const {String} RESET_PASSWORD_ROUTE
 */
export const RESET_PASSWORD_ROUTE = 'resetPassword';

/**
 * Route name of the account confirmation page.
 * @const {String} CONFIRM_ACCOUNT_ROUTE
 */
export const CONFIRM_ACCOUNT_ROUTE = 'confirmAccount';


/**
 * Route name of the SSO login page.
 * @const {String} LOGIN_WITH_SSO_ROUTE
 */
export const LOGIN_WITH_SSO_ROUTE = 'sso';

/**
 * Route name of the page for direct SSO access.
 * @const {String} SSO_DIRECT_ACCESS_ROUTE
 */
export const SSO_DIRECT_ACCESS_ROUTE = 'ssoDirect';

/**
 * Creates the router for the main vue instance.
 *
 * @param {import('vue').VueConstructor} Vue the vue instance
 *
 * @returns {import('vue-router').default} the vue router instance
 *
 * @private
 */
export function createRouter(Vue) {
    // Install the router
    Vue.use(VueRouter);

    // Creates the router instance
    return new VueRouter({
        base: '/',
        mode: 'history',
        routes: [
            // Not found catch all
            { path: '*', redirect: ROUTE_LOGIN },

            // Home
            { path: ROUTE_LOGIN, component: Login },

            // MFA
            { path: ROUTE_LOGIN_WITH_MFA, component: MFAValidation },
            { path: ROUTE_MFA_PHONE, component: MFAPhoneQuery },

            // Single Sign On login
            {
                path: ROUTE_LOGIN_WITH_SSO,
                component: LoginWithSSO,
                name: LOGIN_WITH_SSO_ROUTE,
                props: route => ({
                    initialDomain: route.query.domain
                })
            },
            {
                path: ROUTE_SSO_DIRECT_ACCESS,
                component: SsoRedirect,
                name: SSO_DIRECT_ACCESS_ROUTE
            },

            // Send reset token
            { path: ROUTE_SEND_RESET_TOKEN, component: ResetRequest, name: SEND_RESET_TOKEN_ROUTE },

            // Validate account
            {
                path: ROUTE_VALIDATE_ACCOUNT,
                component: GenerateActivationLink,
                name: SEND_VALIDATE_ACCOUNT_REQUEST_ROUTE,
                props: route => ({
                    email: route.query.email
                })
            },

            // Reset password
            {
                path: ROUTE_RESET_PASSWORD,
                component: NewPassword,
                name: RESET_PASSWORD_ROUTE,
                props: route => ({
                    resetToken: route.query.reset_token,
                    resetEmail: route.query.reset_email
                })
            },

            // Reset password MFA check
            {
                path: ROUTE_RESET_PASSWORD_MFA,
                component: NewPasswordMFA,
            },

            // Account confirmation
            {
                path: ROUTE_CONFIRM_ACCOUNT,
                component: ConfirmAccount,
                name: CONFIRM_ACCOUNT_ROUTE,
                props: route => route.query
            }
        ]
    });
}
