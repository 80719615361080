// Providers
import WindowProvider from 'libs/providers/window';

// Vanilla services
import AccountService from 'libs/services/account';
import SingleSignOnService from 'libs/services/sso';
import RouterService from 'libs/services/router';
import SessionService from 'libs/services/session';
import StorageService from 'libs/services/storage';

const routerService = new RouterService(new WindowProvider(window.location));

export const services = {
    account: new AccountService(),
    router: routerService,
    session: new SessionService(),
    sso: new SingleSignOnService({ router: routerService, store: new StorageService() })
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.prototype.$services = services;
    }
};
