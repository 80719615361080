import 'systemjs';

// Main Vue runtime
import Vue from 'vue';

// Utils
import { createRouter } from 'auth/core/router';
import { createLocales } from 'auth/core/locales';

import Utils from 'auth/core/utils';
import Services from 'auth/core/services';
import Plugins from 'auth/core/plugins';

// App root
import Auth from './pages/Auth.vue';

// Core plugins registration
Vue.use(Utils);
Vue.use(Services);
Vue.use(Plugins);

window.BSTG.runtime = new Vue({
    el: '#auth',
    name: 'Backstage',
    router: createRouter(Vue),
    render: h => h(Auth),
    i18n: createLocales(Vue),
    created() {
        this.$utils.uiTheme.applyTheme({}, 'light');
    }
});
