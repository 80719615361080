// Plugins
import * as VeeValidate from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import Notifications from 'vue-notification';

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        Object.keys(rules).forEach(rule => {
            VeeValidate.extend(rule, { ...rules[rule] });
        });

        const GLOBAL_COMPONENTS = {
            'validation-provider': VeeValidate.ValidationProvider,
            'validation-observer': VeeValidate.ValidationObserver
        };

        // Global components registration
        for (const componentName of Object.keys(GLOBAL_COMPONENTS)) {
            app.component(componentName, GLOBAL_COMPONENTS[componentName]);
        }

        app.use(Notifications);

        // V-Tooltip are required for ControlDecorator
        // but in reality we don't use them in the auth app.
        // With this line we mock v-tooltip.
        app.directive('tooltip', () => {});
    }
};
