{
    "login": {
        "errors": {
            "account_deactivated": "Your account has been disabled, contact your admin to enable it.",
            "account_locked": "Account is locked. Please contact support or request a new password to unlock it.",
            "account_not_activated": "Your account hasn't been activated. Please generate a new link.",
            "activation_token_expired": "Your token has expired, please generate a new one using the Forgot password link.",
            "clause_not_accepted": "You need to accept clauses before proceeding.",
            "expired_token": "The link has expired, please {0}.",
            "generic": "Unfortunately we can't activate your account. Please try again later.",
            "generic_try_again": "Something unforeseen happened during the process. Please try again or contact support.",
            "invalid_code": "Incorrect code. Please try again",
            "invalid_email": "Email cannot be empty.",
            "invalid_link": "The link is invalid, please {0}.",
            "invalid_password": "Password cannot be empty.",
            "login_failed": "Invalid email or password.",
            "logout_before_login": "You need to logout before activating your account.",
            "mfa_client_error": "There was an error during our MFA process. Please try again or contact support.",
            "missing_acceptance_clauses": "Please accept all the clauses.",
            "password_confirmation_mismatch": "Password confirmation does not match password",
            "password_expired": "Your password has expired",
            "password_login_disabled": "Password login is disabled. Use instead the Enterprise Login with SAML organization \"{0}\".",
            "password_reuse": "This password cannot be reused.",
            "phone_token_expired": "Your token has expired, please start the login process anew.",
            "saml_org_not_found": "The SAML organization name is not correct. Try a different one.",
            "short_password": "Password is too short. Minimum {0} characters are required.",
            "validation_email_not_sent": "There was an error sending the new validation email to {0}. Please try again or contact support."
        },
        "forms": {
            "actions": {
                "change_password": "Change password",
                "confirm_account": "Confirm account",
                "logout": "Logout",
                "send_reset_email": "Send reset email",
                "sign_in": "Sign in"
            },
            "email": "Email",
            "fieldsets": {
                "account_activation": "Activate Account",
                "login_with_sso": "Enter your organization’s SSO domain",
                "reset_password": "Password Reset",
                "reset_password_request": "Request Password Reset"
            },
            "login": "Backstage login",
            "mfa": {
                "code": "Backstage MFA code verification",
                "enter_code": "Enter the code we've sent to your phone number ending with {0}.",
                "enter_phone": "To help us keep your account secure we will send a code to your phone number. This number will be used solely for security purpose.",
                "phone": "Backstage MFA phone input",
                "phone_field": "Phone number",
                "remember_me": "Remember me for 14 days"
            },
            "name": "Full name",
            "new_password": "Enter a new password",
            "password": "Password",
            "password_confirmation": "Confirm the new password",
            "password_form": {
                "confirmation": {
                    "label": "Password confirmation",
                    "placeholder": "Confirm password"
                },
                "new_password": {
                    "label": "Password",
                    "placeholder": "Enter a new password"
                },
                "your_email": "Your email"
            },
            "sso_domain": "SSO domain",
            "strength": {
                "good": "Good. {0}",
                "good_job": "Good job!",
                "strong": "Strong. {0}",
                "too_short": "Password is too short. Minimum {0} characters are required.",
                "very_weak": "Very Weak. {0}",
                "weak": "Weak. {0}"
            },
            "submit": "Submit",
            "tips": {
                "bookmark_text": "You can bookmark this URL for direct access: {0}",
                "new_password": "Passwords should contain both letters and numbers and are case sensitive."
            }
        },
        "links": {
            "forgot_password": "Forgot password?",
            "login": "login",
            "login_with_sso": "Enterprise Login",
            "new_link": "Generate a new link",
            "privacy": "Privacy Policy",
            "request_new_token": "Request new link",
            "resend_code": "Resend a code",
            "reset_password": "Reset password",
            "return_to_login": "Return to login",
            "send_code": "Send a code",
            "support": "Support",
            "website": "SpotMe.com",
            "website_onomi": "Onomi.io"
        },
        "messages": {
            "account_activated": "Your accound has been activated. You can now log in.",
            "code_resent": "Code resent to your phone",
            "code_resent_error": "An error happened while sending a code.",
            "password_resetted": "Your password has been changed. Please log in.",
            "reset_email_sent": "If a matching account was found, an email was sent to {0} to allow you to reset your password. If you have not received the email, please check your spam folder and verify that you entered the correct email address.",
            "validation_email_sent": "A new validation email has been sent to {0}",
            "weak_password": "Password is too weak, choose a stronger one and try again."
        },
        "title": "Welcome to {0}"
    }
}
