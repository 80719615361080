import * as assets from 'libs/utils/assets';
import * as constants from 'libs/utils/constants';
import * as locales from 'libs/utils/locales';
import * as uiTheme from 'libs/utils/ui-theme';
import * as url from 'libs/utils/url';

export const utils = {
    assets, locales, uiTheme, url
};

export default {
    /** @param {import('vue').VueConstructor} app */
    install: (app) => {
        app.prototype.$const = constants;
        app.prototype.$utils = utils;
    }
};

